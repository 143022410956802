/**
 * Group/Subgroup utility functions for searching and traversing the structure.
 */

/**
   * Finds the group given the id.
   *
   * @param {Array} groups - Array of group structure objects
   * @param {Number} groupId - id of group to search
   * @returns {Object}
   */
export function findGroup (groups, groupId) {
  return groups.reduce((a, group) => {
    if (a) return a
    if (group.id === groupId) return group
    if (group.children) return findGroup(group.children, groupId)
  }, null)
}

/**
 * Finds the group that contains the property.
 *
 * @param {Array} groups - Array of group structure objects
 * @param {Number} propertyId - id of property to search
 * @returns {Object}
 */
export function findGroupWithProperty (groups, propertyId) {
  return groups.reduce((a, group) => {
    if (a) return a
    if (group.properties.some(property => property.id === propertyId)) return group
    if (group.children) return findGroupWithProperty(group.children, propertyId)
  }, null)
}

/**
 * Recursive function that returns all nested groups within the structure.
 *
 * @param {Object} group - group structure to traverse
 * @returns {Array}
 */
export function getSubgroups (group) {
  const subgroups = JSON.parse(JSON.stringify(group.children))
  for (const child of group.children) {
    subgroups.push(...getSubgroups(child))
  }
  return subgroups
}

/**
 * Recursive function that returns all the nested properties within the structure.
 * @param {Object} group - group structure to traverse
 * @returns {Array} - array of properties
 */
export function getProperties (group) {
  const properties = JSON.parse(JSON.stringify(group.properties))
  for (const subgroup of group.children) {
    properties.push(...getProperties(subgroup))
  }
  return properties
}

/**
 * Recursive function that returns the count of all nested properties
 *
 * @param {Object} group - group structure to traverse
 * @returns {Number}
 */
export function propertyCount (group) {
  let count = group.properties.length
  for (const subgroup of group.children) {
    count += propertyCount(subgroup)
  }
  return count
}

/**
 * Removes the 'children' key value pair from groups if empty.
 * Element UI's cascander component still displays a menu even if it is empty so we want to get rid of this.
 *
 * @param {Array} groups
 */
export function removeEmptyChildrenFromGroups (groups) {
  const newGroups = []
  for (const group of groups) {
    if (group.children.length) {
      removeEmptyChildrenFromGroups(group.children)
    } else {
      delete group.children
    }
    newGroups.push(group)
  }
  return newGroups
}
