<template>
  <div id="rooof-account-form">
    <el-card>
      <el-form
        ref="rooof-account-form"
        v-loading="loadingAccount"
        :model="form"
        :rules="rules"
        label-width="130px"
        label-position="left"
        size="medium"
      >
        <h1 class="form-header">
          General
        </h1>

        <el-form-item label="Name" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>

        <el-form-item label="Email" prop="email">
          <el-input v-model="form.email" />
        </el-form-item>

        <div v-if="newsletter">
          <el-form-item label="Add to Newsletter" prop="send_newsletter">
            <el-checkbox
              v-model="form.send_newsletter"
              :disabled="(form.is_staff||form.is_superuser||form.is_poster)"
            />
          </el-form-item>
        </div>

        <el-form-item label="Title" prop="title">
          <el-input v-model="form.title" />
        </el-form-item>

        <el-form-item label="Phone" prop="phone">
          <el-input v-model="form.phone" />
        </el-form-item>

        <el-form-item label="Is Active" prop="is_active">
          <el-checkbox v-model="form.is_active" />
        </el-form-item>

        <div v-if="!simple">
          <el-form-item label="Is Staff" prop="is_staff">
            <el-checkbox
              v-model="form.is_staff"
              @change="disableNewsletter"
            />
          </el-form-item>

          <el-form-item label="Is Admin" prop="is_superuser">
            <el-checkbox
              v-model="form.is_superuser"
              @change="disableNewsletter"
            />
          </el-form-item>

          <el-form-item label="Is Poster" prop="is_poster">
            <el-checkbox
              v-model="form.is_poster"
              @change="disableNewsletter"
            />
          </el-form-item>

          <el-form-item label="Is Corporate" prop="is_corporate">
            <el-checkbox v-model="form.is_corporate" />
          </el-form-item>

          <el-form-item
            class="capitalize"
            label="SSO Type"
            prop="sso_type"
          >
            {{ form.sso_type }}
          </el-form-item>

          <el-form-item label="Password" prop="has_password">
            {{ form.has_password ? 'Yes' : 'No' }}
          </el-form-item>

          <el-form-item label="Invoicing Groups" class="groups-field">
            <el-select
              v-model="invoicingGroupCompany"
              placeholder="Company"
              filterable
              value-key="id"
              @change="invoicingCompanySelectHandler"
            >
              <el-option
                v-for="company in companies"
                :key="company.id"
                :label="company.human_name"
                :value="company.id"
              />
            </el-select>
            <el-cascader
              ref="invoicing_groups"
              placeholder="Invoicing Groups"
              :options="invoicingGroupOptions"
              :props="{ expandTrigger: 'hover' }"
              popper-class="accounts-cascader"
            >
              <template slot-scope="{ data }">
                <div @click.stop="addGroup(data, 'invoicing_groups')">
                  {{ data.name }}
                </div>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item class="group-tags">
            <template v-for="(memberships, company) in invoicingGroups">
              <h5 :key="company">
                {{ company }}
              </h5>
              <template v-for="membership in memberships">
                <el-tag
                  :key="membership.id"
                  closable
                  :type="membership.pending ? 'success' : ''"
                  @close="removeGroup(membership.group, 'invoicing_groups')"
                >
                  {{ formatGroupStructure(membership.group) }}
                </el-tag>
              </template>
            </template>
          </el-form-item>

          <el-form-item label="Reporting Groups" class="groups-field">
            <el-select
              v-model="reportingGroupCompany"
              placeholder="Company"
              filterable
              value-key="id"
              @change="reportingCompanySelectHandler"
            >
              <el-option
                v-for="company in companies"
                :key="company.id"
                :label="company.human_name"
                :value="company.id"
              />
            </el-select>
            <el-cascader
              ref="postengine_groups"
              placeholder="Reporting Groups"
              :options="reportingGroupOptions"
              :props="{ expandTrigger: 'hover' }"
              popper-class="accounts-cascader"
            >
              <template slot-scope="{ data }">
                <div @click.stop="addGroup(data, 'postengine_groups')">
                  {{ data.name }}
                </div>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item class="group-tags">
            <template v-for="(memberships, company) in reportingGroups">
              <h5 :key="company">
                {{ company }}
              </h5>
              <div
                v-for="membership in memberships"
                :key="membership.id"
                class="group-tags-row"
              >
                <el-tag
                  v-if="membership.group.name"
                  closable
                  :type="membership.pending ? 'success' : ''"
                  @close="removeGroup(membership.group, 'postengine_groups')"
                >
                  {{ formatGroupStructure(membership.group) }}
                </el-tag>
                <div>
                  <el-select v-model="membership.group.can_edit_scheduled_ads" size="small">
                    <el-option label="Can edit scheduled ads" :value="true" />
                    <el-option label="Cannot edit scheduled ads" :value="false" />
                  </el-select>
                  <el-select v-model="membership.group.can_edit_posting_data" size="small">
                    <el-option label="Can edit posting data" :value="true" />
                    <el-option label="Cannot edit posting data" :value="false" />
                  </el-select>
                </div>
              </div>
            </template>
          </el-form-item>

          <el-form-item label="Rooof Properties">
            <el-select
              v-model="form.rooof_properties"
              :remote-method="fetchProperties"
              :loading="loadingProperties"
              value-key="id"
              placeholder="Type to search"
              class="multi-select"
              filterable
              multiple
              remote
            >
              <el-option
                v-for="property in properties"
                :key="property.id"
                :label="property.name"
                :value="property"
              >
                <span class="select-option-value">
                  {{ property.name }}
                </span>
                <span class="select-option-value-alt">
                  {{ property.groups.join(', ') }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="property-tags">
            <div
              v-for="property in form.rooof_properties"
              :key="property.id"
              class="property-tag-row"
            >
              <el-tag
                closable
                collapse-tags
                @close="removeProperty(property.id)"
              >
                {{ property.name }}
              </el-tag>
              <div>
                <el-select v-model="property.can_edit_scheduled_ads" size="small">
                  <el-option label="Can edit scheduled ads" :value="true" />
                  <el-option label="Cannot edit scheduled ads" :value="false" />
                </el-select>
                <el-select v-model="property.can_edit_posting_data" size="small">
                  <el-option label="Can edit posting data" :value="true" />
                  <el-option label="Cannot edit posting data" :value="false" />
                </el-select>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="User Permissions">
            {{ form.user_permissions }}
          </el-form-item>

          <div v-if="isUpdate">
            <el-form-item label="Last Login" prop="last_login">
              {{ form.last_login | dateTimeString }}
            </el-form-item>

            <el-form-item label="Created" prop="created">
              {{ form.created | dateTimeString }}
            </el-form-item>

            <el-form-item label="Last Modified" prop="last_modified">
              {{ form.last_modified | dateTimeString }}
            </el-form-item>
            <h1 class="form-header">
              Emails
            </h1>
            <el-form-item label="Email Reports">
              <template v-for="(memberships, company) in reportingGroups">
                <template v-if="memberships.some(membership => membership.group.name && getReportFrequencies(membership, 'postengine_groups').length)">
                  <b :key="company">
                    {{ company }}
                  </b>
                  <template v-for="membership in memberships">
                    <div v-if="membership.group.name && getReportFrequencies(membership, 'postengine_groups').length" :key="membership.id">
                      {{ formatGroupStructure(membership.group) }} - {{ getReportFrequencies(membership, 'postengine_groups').join(', ') }}
                    </div>
                  </template>
                </template>
              </template>
            </el-form-item>
            <el-form-item label="Invoice Summaries">
              <template v-for="(memberships, company) in invoicingGroups">
                <template v-if="memberships.some(membership => getReportFrequencies(membership, 'invoicing_groups').length)">
                  <b :key="company">
                    {{ company }}
                  </b>
                  <template v-for="membership in memberships">
                    <div v-if="getReportFrequencies(membership, 'invoicing_groups').length" :key="membership.id">
                      {{ formatGroupStructure(membership.group) }} - {{ getReportFrequencies(membership, 'invoicing_groups').join(', ') }}
                    </div>
                  </template>
                </template>
              </template>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-card>

    <div class="form-actions">
      <el-button
        id="submit-btn"
        :loading="loading"
        type="primary"
        @click="onSubmit()"
      >
        Save
      </el-button>
      <el-button
        v-if="isUpdate"
        type="danger"
        @click="onDeactivate()"
      >
        Deactivate
      </el-button>
    </div>
  </div>
</template>

<script>
import RooofAccountAPI from '@/services/api/accounts'
import RooofAPI from '@/services/api/rooof'
import CraigslistAPI from '@/services/api/craigslist'
import InvoicesAPI from '@/services/api/invoices'
import { removeEmptyChildrenFromGroups } from '@/utils/groups'
import { constants } from '@/utils/constants'

export default {
  name: 'RooofAccountForm',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    newsletter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        name: '',
        email: '',
        title: '',
        phone: '',
        has_password: false,
        send_newsletter: true,
        is_active: true,
        is_staff: false,
        is_superuser: false,
        is_poster: false,
        is_corporate: false,
        rooof_properties: [],
        user_permissions: [],
        postengine_groups: [],
        invoicing_groups: []
      },
      rules: {
        name: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
        ]
      },
      searchProperties: [],
      loadingAccount: false,
      loadingProperties: false,
      companies: [],
      reportingGroupCompany: null,
      invoicingGroupCompany: null,
      reportingGroupOptions: [],
      invoicingGroupOptions: [],
      permissions: [],
      permissionTypes: ['edit_scheduled_ads', 'edit_posting_data']
    }
  },
  computed: {
    isUpdate () {
      // The presence of an id in the route path
      // indicates we're updating this resource
      return Boolean(this.$route.params.id)
    },
    properties () {
      // We need to manage two lists of options to display in the
      // property select; one for selected account properties and
      // one for the remote search results (de-duplicated)
      const unique = {}
      for (const prop of this.form.rooof_properties) {
        unique[prop.id] = prop
      }
      for (const prop of this.searchProperties) {
        if (!unique[prop.id]) {
          unique[prop.id] = prop
        }
      }
      return Object.values(unique).sort((a, b) => a.name.localeCompare(b.name))
    },
    /**
     * Organizes form.postengine_groups by company
     */
    reportingGroups () {
      const groups = {}
      if (this.form.postengine_groups) {
        for (const membership of this.form.postengine_groups) {
          if (groups.hasOwnProperty(membership.group.company.human_name)) {
            groups[membership.group.company.human_name].push(membership)
          } else {
            groups[membership.group.company.human_name] = [membership]
          }
        }
      }
      return groups
    },
    /**
     * Organizes form.invoicing_groups by company
     */
    invoicingGroups () {
      const groups = {}
      if (this.form.invoicing_groups) {
        for (const membership of this.form.invoicing_groups) {
          if (groups.hasOwnProperty(membership.group.company.human_name)) {
            groups[membership.group.company.human_name].push(membership)
          } else {
            groups[membership.group.company.human_name] = [membership]
          }
        }
      }
      return groups
    }
  },
  async created () {
    this.companies = await RooofAPI.companies.summary()
  },
  mounted () {
    if (this.isUpdate) {
      this.fetchAccount()
    }
  },
  methods: {
    /**
     * Fetch a rooof account from the api.
     */
    async fetchAccount () {
      this.loadingAccount = true
      try {
        const response = await RooofAccountAPI.users.retrieve(this.$route.params.id)
        const propertiesCopy = response.rooof_properties
        response.rooof_properties = []

        // fetch account object permissions
        const permissionsResponse = await RooofAccountAPI.permissions.list(this.$route.params.id)
        this.permissions = permissionsResponse

        // set postengine_group permissions
        for (const membership of response.postengine_groups) {
          this.setPermissions(membership.group, 'postenginegroup')
        }

        this.form = response

        this.loadingAccount = false

        // Tranform list of property ids into property objects (non-blocking)
        if (propertiesCopy.length) {
          for (const id of propertiesCopy) {
            RooofAPI.properties.retrieve(id).then(prop => {
              this.setPermissions(prop, 'craigslistproperty')
              this.form.rooof_properties.push(prop)
              this.form.rooof_properties.sort((a, b) => a.name.localeCompare(b.name))
            })
          }
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          this.$message({
            message: 'Account does not exist',
            type: 'warning',
            duration: 3500
          })
          this.$router.push({ name: 'RooofAccounts' })
        } else {
          const details = err.response ? err.response.data : null
          this.$rfAlert.error(this, err, details)
          this.loadingAccount = false
        }
      }
    },
    /**
      * Sets property/group edit permissions in the UI based on the fetched permissions endpoint.
     * This transforms the actual object being passed in
     * @param {Object} object - property or group
     * @param {String} model - 'craigslistproperty' or 'postenginegroup'
     */
    setPermissions (object, model) {
      const permissions = this.permissions.filter(permission => {
        return permission.object_pk === object.id.toString() &&
        permission.content_type.model === model
      }).map(permission => {
        return permission.permission.codename
      })
      for (const permissionType of this.permissionTypes) {
        object['can_' + permissionType] = permissions.includes(permissionType)
      }
    },
    /**
     * Handler for when a company has been selected for reporting groups.
     * Calls the API to fetch list of reporting groups.
     *
     * @param {Number} companyId
     */
    async reportingCompanySelectHandler (companyId) {
      if (!companyId) return
      try {
        const params = { company_id: companyId }
        const response = await CraigslistAPI.groups.list(params)
        this.reportingGroupOptions = removeEmptyChildrenFromGroups(response[0].children)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
    },
    /**
     * Handler for when a company has been selected for invoicing groups.
     * Calls the API to fetch list of invoicing groups with exposed top level.
     *
     * @param {Number} companyId
     */
    async invoicingCompanySelectHandler (companyId) {
      if (!companyId) return
      try {
        const params = { company_id: companyId }
        const response = await InvoicesAPI.groups.list(params)
        const topLevel = JSON.parse(JSON.stringify(response[0]))
        topLevel.name = `${topLevel.company.human_name} Entire Company`
        delete topLevel.children
        const options = [topLevel, ...removeEmptyChildrenFromGroups(response[0].children)]
        this.invoicingGroupOptions = options
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
    },
    /**
     * Adds a group to the form.
     *
     * @param {Object} group
     * @param {String} prop - postengine_groups / invoicing_groups
     */
    addGroup (group, prop) {
      const exists = this.form[prop].find(membership => membership.group.id === group.id)
      if (exists) {
        this.$message({
          message: `Group ${group.name} already exists.`,
          type: 'warning',
          duration: 3500
        })
        return
      }
      const groupToAdd = {
        pending: true,
        group: {
          id: group.id,
          name: group.name,
          company: group.company,
          parents: []
        }
      }
      this.setPermissions(groupToAdd.group, 'postenginegroup')
      this.form[prop].push(groupToAdd)
      this.$refs[prop].toggleDropDownVisible()
    },
    /**
     * Removes a group from the form.
     *
     * @param {Object} group
     * @param {String} prop - postengine_groups / invoicing_groups
     */
    removeGroup (group, prop) {
      this.form[prop] = this.form[prop].filter(membership => membership.group.id !== group.id)
    },
    /**
     * Returns the group name with all it's ancestors.
     *
     * @param {Object} group
     * @returns {String}
     */
    formatGroupStructure (group) {
      let structure = group.name || `${group.company.human_name} Entire Company`
      for (const parent of group.parents) {
        if (parent.name) {
          structure = parent.name + ' > ' + structure
        }
      }
      return structure
    },
    /**
     * Gets all email report or invoice summary frequencies.
     *
     * @param {object} membership
     * @param {String} prop - postengine_groups / invoicing_groups
     * @returns {Array} - e.g. ['Weekly', 'Monthly']
     */
    getReportFrequencies (membership, prop) {
      const frequencies = []
      const options = prop === 'postengine_groups' ? constants.emailReports : constants.invoiceSummaries
      for (const report of options) {
        if (membership[report.value]) {
          frequencies.push(report.label)
        }
      }
      return frequencies
    },
    /**
     * Fetch a list of properties from the api.
     *
     * @param {String} searchValue
     */
    async fetchProperties (searchValue) {
      if (searchValue !== '') {
        this.loadingProperties = true
        try {
          const params = {
            name__icontains: searchValue,
            page_size: 50
          }
          const response = await RooofAPI.properties.listPage(params)
          for (const property of response.results) {
            this.setPermissions(property, 'craigslistproperty')
          }
          this.searchProperties = response.results
        } catch (err) {
          const details = err.response ? err.response.data : null
          this.$rfAlert.error(this, err, details)
        }
        this.loadingProperties = false
      }
    },
    /**
     * Update the checkbox based on user role
     * We don't want to allow staff, posters and admin to be added to the newsletter
     */
    disableNewsletter () {
      if (this.form.is_staff || this.form.is_superuser || this.form.is_poster) {
        this.form.send_newsletter = false
      } else {
        this.form.send_newsletter = true
      }
    },
    /**
     * Removes a property from the account
     *
     * @param {Number} propertyId
     */
    removeProperty (propertyId) {
      this.form.rooof_properties = this.form.rooof_properties.filter(property => property.id !== propertyId)
    },
    /**
     * onClick handler for submit button.
     */
    onSubmit () {
      this.$refs['rooof-account-form'].validate(valid => {
        if (valid) {
          // Only send a subset of the fields (for now)
          const data = {
            name: this.form.name,
            email: this.form.email,
            send_newsletter: this.form.send_newsletter,
            title: this.form.title,
            phone: this.form.phone,
            is_active: this.form.is_active,
            is_staff: this.form.is_staff,
            is_superuser: this.form.is_superuser,
            is_poster: this.form.is_poster,
            is_corporate: this.form.is_corporate,
            rooof_properties: this.form.rooof_properties.map(el => el.id),
            postengine_groups: this.form.postengine_groups,
            invoicing_groups: this.form.invoicing_groups,
            object_permissions: this.preparePermissions(this.form.rooof_properties, this.form.postengine_groups)
            // user_permissions: this.form.user_permissions
          }
          this.$emit('submit', data)
        }
      })
    },
    /**
     * Prepares the permissions for the API payload
     *
     * @param {Array} properties
     * @param {Array} memberships
     * @returns {Array} newPermissions
    */
    preparePermissions (properties, memberships) {
      let newPermissions = JSON.parse(JSON.stringify(this.permissions))
      newPermissions = this.parsePermissions(newPermissions, properties, 'craigslistproperty')
      newPermissions = this.parsePermissions(newPermissions, memberships.map(m => m.group), 'postenginegroup')
      return newPermissions
    },
    /**
     * Given the existing permissions, return a new set based on any
     * added/removed permissions in the property or group
     *
     * @param {Array} permissions
     * @param {Array} objects - array of properties or groups
     * @param {String} model - 'craigslistproperty' or 'postenginegroup'
     * @returns {Array} permissions
     */
    parsePermissions (permissions, objects, model) {
      for (const object of objects) {
        for (const permissionType of this.permissionTypes) {
          const existingPermission = permissions.find(permission => {
            return permission.object_pk === object.id.toString() &&
                  permission.content_type.model === model &&
                  permission.permission.codename === permissionType
          })
          if (!existingPermission && object['can_' + permissionType]) {
            permissions.push({
              content_type: { app_label: 'craigslist', model: model },
              permission: { codename: permissionType },
              object_pk: object.id.toString()
            })
          }
          if (existingPermission && !object['can_' + permissionType]) {
            permissions = permissions.filter(permission => {
              return !(permission.object_pk === existingPermission.object_pk &&
                permission.permission.codename === existingPermission.permission.codename)
            })
          }
        }
      }
      return permissions
    },
    /**
     * onClick handler for deactivate button.
     */
    onDeactivate () {
      this.$msgbox({
        type: 'warning',
        title: 'Deactivate Account',
        message: `This will deactivate <strong>${this.form.email || this.form.name}</strong>.
        Unsaved changes will be lost. Continue?`,
        showCancelButton: true,
        confirmButtonText: 'Deactivate',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.$emit('deactivate', this.form)
      }).catch(() => {
        // user cancelled, do nothing
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}
.multi-select {
  width: 371px;

  ::v-deep .el-select__tags .el-tag {
    display: none;
  }
}
.select-option-value {
  float: left;
}
.select-option-value-alt {
  float: right;
  padding-left: 20px;
  padding-right: 20px;
  color: #949ba5;
  font-size: 13px;
}
.form-actions {
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-header {
  margin-top: 0;
}
.groups-field {
  margin-bottom: 0.5em;
  ::v-deep .el-form-item__content {
    display: flex;
    .el-select {
      flex: 1;
      margin-right: 1em;
    }
    .el-cascader {
      flex: 2;
    }
  }
}
.group-tags {
  ::v-deep .el-form-item__content {
    .el-tag {
      margin-right: 0.5em;
    }
  }
  h5 {
    margin: 0;
  }
  .group-tags-row {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-select:first-of-type {
      margin-right: 1em;
    }
  }
}

.property-tags {
  .property-tag-row {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-select:first-of-type {
      margin-right: 1em;
    }
  }
}
</style>

<style lang="scss">
/* Can't be scoped since it is dynamically taken out of component in the DOM */
.accounts-cascader {
  .el-cascader-menu .el-cascader-node {
    padding: 0;
    .el-cascader-node__label {
      padding: 0;
      div {
        padding: 0 30px;
      }
    }
  }
}
</style>
